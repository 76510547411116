import { ApolloClient } from '@apollo/client'
import type { ApolloContext } from '~/services/types/Gql'
import updateLoginTimezone from '@/graphql/timezone/updateLoginTimezone.gql'
import updateTenantTimezone from '@/graphql/timezone/updateTenantTimezone.gql'
import getTenantTimezone from '@/graphql/timezone/getTenantTimezone.gql'
import { UpdateLoginSettings, UpdateTenantSettings, TenantSettingsType } from '@/types/generated'

interface ITimezoneGql {
  $apollo: ApolloClient<any>
  updateLoginTimezone: (
    timezone: string
  ) => Promise<UpdateLoginSettings>
  updateTenantTimezone: (
    tenantId: string,
    timezone: string
  ) => Promise<UpdateTenantSettings>
  getTenantTimezone: (
    tenantId: string,
  ) => Promise<TenantSettingsType>
}
export default class TimezoneGql implements ITimezoneGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  updateLoginTimezone (timezone: string): Promise<UpdateLoginSettings> {
    return this.$apollo.mutate({
      mutation: updateLoginTimezone,
      variables: {
        timezone
      }
    }).then(result => result.data.updateLoginSettings)
  }

  updateTenantTimezone (tenantId: string, timezone: string): Promise<UpdateTenantSettings> {
    return this.$apollo.mutate({
      mutation: updateTenantTimezone,
      variables: {
        tenantId,
        timezone
      }
    }).then(result => result.data.updateTenantSettings)
  }

  getTenantTimezone (tenantId: string): Promise<TenantSettingsType> {
    return this.$apollo.query({
      query: getTenantTimezone,
      variables: {
        tenantId
      }
    }).then(result => result.data.tenantSettingsById)
  }
}
